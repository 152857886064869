<template>
    <Modal :title="title" v-model="show" class-name="vertical-center-modal"
           @on-cancel="cancel"  @on-ok="save" :loading="loading">
        <Form :model="formItem" :label-width="80">
            <FormItem label="父编码">
                <Input v-model="formItem.parentCode" :disabled="true" placeholder="请输入父菜单编码"></Input>
            </FormItem>
            <FormItem label="菜单名称">
                <Input v-model="formItem.permissionName" placeholder="请输入菜单名称"></Input>
            </FormItem>
            <FormItem label="菜单编码">
                <Input v-model="formItem.permissionCode" placeholder="请输入菜单编码"></Input>
            </FormItem>
            <FormItem label="状态">
                <RadioGroup v-model="formItem.status">
                    <Radio label="1">启用</Radio>
                    <Radio label="0">禁用</Radio>
                </RadioGroup>
            </FormItem>
            <FormItem label="排序号">
                <Input v-model="formItem.sortby" type="number" placeholder="请输入排序号"></Input>
            </FormItem>
            <FormItem label="URL">
                <Input v-model="formItem.url" placeholder="请输入URL"></Input>
            </FormItem>
            <FormItem label="图标">
                <Input v-model="formItem.icon" placeholder="请输入图标"></Input>
            </FormItem>
            <FormItem label="备注">
                <Input v-model="formItem.remark" placeholder="请输入备注"></Input>
            </FormItem>
        </Form>
    </Modal>
</template>
<script>
    export default {
        name: "UpdateMenu",
        data(){
            return {
                title:"修改菜单",
                formItem:{
                    uuid:"",
                    permissionName: "",
                    permissionCode: "",
                    parentCode: "",
                    parentUuid: "",
                    status: "1",
                    sortby: null,
                    url:"",
                    icon: "",
                    remark: ""
                },
                show: false,
                loading: false
            }
        },
        props: {
            updateMenuShow:{
                type: Boolean,
                default: true,
            },
            permission:{}
        },
        methods:{
            cancel() {
                this.$parent.updateMenuShow = false
            },
            save(){
                this.loading = true
                console.log(this.formItem)
                this.post("/permission/menu/update", this.formItem).then(() => {
                    this.$Message.info({
                        content: '保存成功'
                    });
                    this.$parent.loadData();
                    this.loading = false;
                    this.cancel()
                }).catch(() => {
                    this.loading = false
                })
            }
        },
        watch:{
            updateMenuShow(){
                this.show = this.updateMenuShow;
            },
            show(){
                if(this.show){
                    this.formItem = Object.assign(this.formItem, this.permission);
                    this.formItem.status = this.formItem.status + "";
                }
            }
        }
    }
</script>

<style scoped>
    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ivu-modal{
        top: 0;
    }
</style>