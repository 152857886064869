<template>
    <div class="height100">
        <Row class="height100" :gutter="16">
            <Col span="4" class-name="height100">
                <!--<Tree ref="tree" :data="permissionInfo" expand-node select-node></Tree>-->
                <el-tree ref="tree" class="height100 lift_col"
                    :data="permissionInfo"
                    node-key="uuid"
                    default-expand-all
                    :expand-on-click-node="false"
                    :highlight-current="true"
                    @node-click="nodeClick"
                    >
                </el-tree>
            </Col>
            <Col span="20">
                <Button type="primary" class="margin_bottom_10" @click.native="showAddMenu">新增</Button>
                <Table :columns="columns" :data="data">
                    <template slot-scope="{ row }" slot="status">
                        {{ row.isDefault == 1 ? "内置菜单" : row.status == 1 ? "启用" : "禁用" }}
                    </template>
                    <template slot-scope="{ row }" slot="action">
                        <Button size="small" class="margin_right_10" @click.native="updateStatus(row)" v-if="row.isDefault == 0" :loading="updateStatusLoading">{{ row.status == 0 ? "启用" : "禁用" }}</Button>
                        <Button size="small" type="primary" @click.native="updateMenu(row)" v-if="row.isDefault == 0" class="margin_right_10" >修改</Button>
                        <Button size="small" type="error" @click.native="delMenu(row)" v-if="row.isDefault == 0" class="margin_right_10" >删除</Button>
                    </template>
                </Table>
                <Page class="margin_10" v-if="page.pages > 1" :total="page.total" :current="page.pageNum" @on-change="changePage" show-elevator show-sizer/>
            </Col>
        </Row>
        <AddMenu :addMenuShow="addMenuShow" :parentPermission="parentPermission"></AddMenu>
        <UpdateMenu :updateMenuShow="updateMenuShow" :permission="permission"></UpdateMenu>
    </div>
</template>

<script>
    import AddMenu from "/src/framework/menu/AddMenu.vue"
    import UpdateMenu from "/src/framework/menu/UpdateMenu.vue"
    export default {
        name: "Menu",
        components:{
            AddMenu,
            UpdateMenu
        },
        data () {
            return {
                addMenuShow: false,
                updateMenuShow: false,
                updateStatusLoading: false,
                parentPermission: {},
                permission: {},
                search: {
                    permissionName: "",
                    permissionCode: "",
                    parentUuid: ""
                },
                permissionInfo:[],
                page:{
                    pageNum: 1,
                    pageSize: 999999,
                    total: 0,
                    pages: 1
                },
                selectedDate: {},
                columns:[
                    {
                        title: '菜单名称',
                        key: 'permissionName'
                    },
                    {
                        title: '菜单编码',
                        key: 'permissionCode'
                    },
                    {
                        title: 'URL',
                        key: 'url'
                    },
                    {
                        title: '状态',
                        slot: 'status'
                    },
                    {
                        title: '序号',
                        key: 'sortby'
                    },
                    {
                        title: '备注',
                        key: 'remark'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: "250px"
                    }
                ],
                data:[],

            }
        },
        created() {
            this.loadData();
        },
        methods : {
            async loadData () {
                let _search = Object.assign({}, this.search);
                this.post("/permission/menu/list", _search).then(value => {
                    let data = value.data.data;
                    let mainMenu = [];
                    data.forEach((o) => {
                        if (!o.parentUuid) {
                            o['children'] = [];
                            o['expand'] = true,
                            o['label'] = o.permissionName;
                            mainMenu.push(o)
                            loop (o, data)
                        }
                    })

                    function loop (menu, menus) {
                        menus.forEach((m) => {
                            if(m.parentUuid == menu.uuid){
                                if(!menu.children){
                                    menu['children'] = []
                                    menu['expand'] = true
                                }
                                m['label'] = m.permissionName;
                                menu.children.push(m)
                                loop(m, menus)
                            }
                        })

                        if(menu.children){
                            menu.children = menu.children.sort((a, b) => {
                                return a.sortby - b.sortby
                            })
                        }
                    }

                    // mainMenu.forEach((m) => {
                    //     data.forEach((o) => {
                    //         if (o.parentUuid == m.uuid) {
                    //             o['label'] = o.permissionName;
                    //             m.children.push(o)
                    //         }
                    //     })
                    // })

                    let rootMenu = [{
                        children: mainMenu,
                        uuid: "",
                        label: "菜单"
                    }]
                    this.permissionInfo = rootMenu;

                    this.$nextTick(() => {
                        if(this.parentPermission.uuid){
                            this.$refs.tree.setCurrentKey(this.parentPermission.uuid);
                        }else {
                            this.$refs.tree.setCurrentKey("");
                        }
                        this.nodeClick(this.$refs.tree.getCurrentNode())
                    });
                })
            },
            loadTableData: function (parentUuid) {
                if(parentUuid == "root"){
                    parentUuid  = "";
                }
                let page = {
                    pageNum : this.page.pageNum - 1,
                    pageSize : this.page.pageSize
                }
                let _search = Object.assign({}, this.search, page , {parentUuid: parentUuid});
                this.post("/permission/menu/page", _search).then(value => {
                    this.data = value.data.data.data
                    this.page = {
                        total: value.data.data.total,
                        pageSize: value.data.data.pageSize,
                        pageNum: value.data.data.pageNum,
                        pages: value.data.data.pages
                    }
                }).catch(reason => {
                    console.log(reason);
                })
            },
            nodeClick(data, node){
                console.log(node)
                if(node && node.level > 3){
                    this.$message({
                        type: 'warning',
                        message: '只能创建三级菜单'
                    });
                    return
                }
                this.page = this.$options.data().page
                this.selectedDate = data;
                this.loadTableData(data.uuid);
            },
            changePage(pageNum){
                this.page.pageNum = pageNum;
                this.loadTableData(this.selectedDate.uuid);
            },
            showAddMenu(){
                this.parentPermission =  this.$refs.tree.getCurrentNode();
                this.addMenuShow = true;
            },
            delMenu(data){
                this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.post("/permission/menu/del", {uuid: data.uuid}).then(() => {
                        this.loadTableData(data.parentUuid);
                        this.$refs.tree.remove(data)
                    }).catch(reason => {
                        console.log(reason);
                        this.$Message.warning(reason.msg)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除操作'
                    });
                });
            },
            updateMenu(data){
                this.permission =  data;
                this.updateMenuShow = true;
            },
            updateStatus(data){
                this.updateStatusLoading = true;
                let status = data.status == 1 ? 0 : 1;
                this.post("/permission/menu/status/update", {uuid: data.uuid, status: status}).then(() => {
                    this.loadData();
                    this.$Message.info({
                        content: status == 1? '启用成功' : "禁用成功"
                    });
                    this.updateStatusLoading = false;
                }).catch(reason => {
                    console.log(reason);
                    this.$Message.info({
                        content: status == 1? '启用失败' : "禁用失败"
                    });
                    this.updateStatusLoading = false;
                })
            },
        }
    }
</script>

<style scoped>
    .lift_col{
        border: 1px solid rgba(152, 168, 163, 0.45);
        overflow: auto;
    }
</style>
